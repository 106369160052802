import React from 'react'
import styled from 'styled-components'

const textColor = '#333'

const Logo = ({ classes }) => (



  <svg viewBox="0 0 819 159" fill="currentColor" className={`${classes ? classes : 'text-brand-500'}`} xmlns="http://www.w3.org/2000/svg">
    <path d="M109.84 34.32V25.3C109.84 11.88 96.42 0.440002 56.71 0.440002C17.11 0.440002 3.69001 10.12 3.69001 23.87V29.26C3.69001 60.94 88.72 43.01 88.72 61.82V65.45C88.72 71.83 82.89 77 56.71 77C30.64 77 24.81 71.06 24.81 64.57V55.99H2.59001V65.01C2.59001 78.43 14.69 89.87 56.71 89.87C98.84 89.87 110.94 80.19 110.94 66.44V61.05C110.94 29.37 25.58 47.3 25.58 28.49V24.86C25.58 18.48 31.41 13.31 56.71 13.31C82.01 13.31 87.95 19.25 87.95 25.74V34.32H109.84Z" fill="currentColor" />
    <path d="M138.993 88H233.703V75.57H161.213V49.39H227.323V38.17H161.213V14.74H231.833V2.31H138.993V88Z" fill="currentColor" />
    <path d="M342.322 88H366.192L318.782 2.2H295.132L246.622 88H269.502L280.282 68.64H332.092L342.322 88ZM287.212 56.21L306.792 20.68L325.492 56.21H287.212Z" fill="currentColor" />
    <path d="M466.063 2.2H360.243V14.74H402.043V88H424.263V14.74H466.063V2.2Z" fill="currentColor" />
    <path d="M584.687 2.2H478.867V14.74H520.667V88H542.887V14.74H584.687V2.2Z" fill="currentColor" />
    <path d="M628.511 75.57V2.31H606.291V88.11H694.731V75.57H628.511Z" fill="currentColor" />
    <path d="M716.428 88H811.138V75.57H738.648V49.39H804.758V38.17H738.648V14.74H809.268V2.31H716.428V88Z" fill="currentColor" />
    <path d="M42.8 132V129.35C42.8 123.2 39.25 119.05 24 119.05H0.450012V158.05H5.85001V142.35H21.2L35.7 158.05H42.6L27.85 142.25C39.85 141.6 42.8 137.65 42.8 132ZM37.4 131.65C37.4 135.55 35.85 138.35 24 138.35H5.85001V123.05H24C35.85 123.05 37.4 126.25 37.4 129.7V131.65Z" fill="currentColor" />
    <path d="M57.2996 158H96.4496V154H62.6996V139.75H93.0996V135.9H62.6996V123.05H95.4996V119.05H57.2996V158Z" fill="currentColor" />
    <path d="M108.805 158H114.055V125.55L148.205 158H153.155V119H147.905V151.5L113.755 119H108.805V158Z" fill="currentColor" />
    <path d="M190.323 118.25C171.223 118.25 166.723 124.6 166.723 131.65V145.35C166.723 152.45 171.223 158.8 190.323 158.8C209.423 158.8 213.923 152.45 213.923 145.35V131.65C213.923 124.6 209.423 118.25 190.323 118.25ZM208.523 132.05V145C208.523 149.4 206.023 154.75 190.323 154.75C174.623 154.75 172.123 149.4 172.123 145V132.05C172.123 127.65 174.623 122.3 190.323 122.3C206.023 122.3 208.523 127.65 208.523 132.05Z" fill="currentColor" />
    <path d="M265.584 119L247.534 152.1L229.084 119H223.184L245.234 158H249.734L271.234 119H265.584Z" fill="currentColor" />
    <path d="M315.273 158H321.423L299.723 119H294.773L272.173 158H278.223L284.223 147.5H309.573L315.273 158ZM286.473 143.5L297.123 124.8L307.373 143.5H286.473Z" fill="currentColor" />
    <path d="M363.45 119H320.05V123H339.05V158H344.45V123H363.45V119Z" fill="currentColor" />
    <path d="M374.374 119V158H379.774V119H374.374Z" fill="currentColor" />
    <path d="M417.881 118.25C398.781 118.25 394.281 124.6 394.281 131.65V145.35C394.281 152.45 398.781 158.8 417.881 158.8C436.981 158.8 441.481 152.45 441.481 145.35V131.65C441.481 124.6 436.981 118.25 417.881 118.25ZM436.081 132.05V145C436.081 149.4 433.581 154.75 417.881 154.75C402.181 154.75 399.681 149.4 399.681 145V132.05C399.681 127.65 402.181 122.3 417.881 122.3C433.581 122.3 436.081 127.65 436.081 132.05Z" fill="currentColor" />
    <path d="M455.043 158H460.293V125.55L494.443 158H499.393V119H494.143V151.5L459.993 119H455.043V158Z" fill="currentColor" />
    <path d="M586.005 134V131.7C586.005 124.6 581.505 118.25 562.405 118.25C543.305 118.25 538.805 124.6 538.805 131.7V145.4C538.805 152.45 543.305 158.8 562.405 158.8C581.505 158.8 586.005 152.45 586.005 145.4V138.35H562.405V141.9H580.605V145C580.605 149.4 578.105 154.75 562.405 154.75C546.705 154.75 544.205 149.4 544.205 145V132.05C544.205 127.65 546.705 122.3 562.405 122.3C578.105 122.3 580.605 127.65 580.605 132.05V134H586.005Z" fill="currentColor" />
    <path d="M642.866 132V129.35C642.866 123.2 639.316 119.05 624.066 119.05H600.516V158.05H605.916V142.35H621.266L635.766 158.05H642.666L627.916 142.25C639.916 141.6 642.866 137.65 642.866 132ZM637.466 131.65C637.466 135.55 635.916 138.35 624.066 138.35H605.916V123.05H624.066C635.916 123.05 637.466 126.25 637.466 129.7V131.65Z" fill="currentColor" />
    <path d="M679.766 118.25C660.666 118.25 656.166 124.6 656.166 131.65V145.35C656.166 152.45 660.666 158.8 679.766 158.8C698.866 158.8 703.366 152.45 703.366 145.35V131.65C703.366 124.6 698.866 118.25 679.766 118.25ZM697.966 132.05V145C697.966 149.4 695.466 154.75 679.766 154.75C664.066 154.75 661.566 149.4 661.566 145V132.05C661.566 127.65 664.066 122.3 679.766 122.3C695.466 122.3 697.966 127.65 697.966 132.05Z" fill="currentColor" />
    <path d="M754.878 119V145C754.878 149.9 752.378 154.75 739.078 154.75C725.778 154.75 723.278 149.9 723.278 145V119H717.878V145.35C717.878 152.95 722.378 158.8 739.078 158.8C755.778 158.8 760.278 152.95 760.278 145.35V119H754.878Z" fill="currentColor" />
    <path d="M799.547 119H775.997V158H781.397V142.35H799.547C814.797 142.35 818.347 138.2 818.347 132.05V129.35C818.347 123.2 814.797 119 799.547 119ZM812.947 131.65C812.947 135.55 811.397 138.35 799.547 138.35H781.397V123.05H799.547C811.397 123.05 812.947 126.25 812.947 129.7V131.65Z" fill="currentColor" />
  </svg>





)

export default Logo


